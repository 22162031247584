<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card dark color="success" class="alineacentro DatosMatriculado">
          <v-card-text class="DatosMatriculado"><strong>MATRICULADO DEL CAPLP</strong></v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12"
        md="3"
        lg="3"
        class="d-flex flex-column align-center"
      >
        <v-img :src="matriculado.foto" class="img-responsive" position="center center" width="250" alt="" />
      </v-col>
      <v-col cols="12"
        md="9"
        lg="9"
      >
        <v-row class="text-center">
          <v-col cols="12">
            <v-card dark color="primary" class="alineaizquierda DatosMatriculado">
                <v-card-text class="DatosMatriculado"><strong>Documento:</strong> {{matriculado.documento}}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="12">
            <v-card dark color="primary" class="alineaizquierda DatosMatriculado">
                <v-card-text class="DatosMatriculado"><strong>Apellidos y Nombres:</strong> {{matriculado.apenom}}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row class="text-center">
          <v-col cols="12">
            <v-card dark color="primary" class="alineaizquierda DatosMatriculado">
                <v-card-text class="DatosMatriculado"><strong>Nombres:</strong> {{matriculado.nombre}}</v-card-text>
            </v-card>
          </v-col>
        </v-row> -->
        <v-row class="text-center">
          <v-col cols="12">
            <v-card dark color="primary" class="alineaizquierda DatosMatriculado">
                <v-card-text class="DatosMatriculado"><strong>Código CF:</strong> {{matriculado.codigoCF}}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <v-expansion-panels focusable v-model=paneldefecto multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="primary DatosMatriculado textoblanco"><strong>Matrículas</strong></v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="text-center">
                <v-col cols="12">
                  <v-data-table
                    dense
                    :headers="colmatriculas"
                    :items="matriculado.matriculas"
                    item-key="name"
                    class="elevation-1"
                    hide-default-footer
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="primary DatosMatriculado textoblanco"><strong>Datos de Contacto</strong></v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="text-center">
                <v-col cols="12">
                  <v-card dark color="secondary" class="alineaizquierda DatosMatriculado">
                    <v-card-text class="DatosMatriculado"><strong>Domicilios</strong></v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-col cols="12">
                  <v-data-table
                    dense
                    :headers="coldomicilios"
                    :items="matriculado.domicilios"
                    item-key="name"
                    class="elevation-1"
                    hide-default-footer
                  />
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-col cols="12">
                  <v-card dark color="secondary" class="alineaizquierda DatosMatriculado">
                    <v-card-text class="DatosMatriculado"><strong>Telefonos</strong></v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-col cols="12">
                  <v-data-table
                    dense
                    :headers="coltelefonos"
                    :items="matriculado.telefonos"
                    item-key="name"
                    class="elevation-1"
                    hide-default-footer
                  />
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-col cols="12">
                  <v-card dark color="secondary" class="alineaizquierda DatosMatriculado">
                    <v-card-text class="DatosMatriculado"><strong>Correos</strong></v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-col cols="12">
                  <v-data-table
                    dense
                    :headers="colcorreos"
                    :items="matriculado.correos"
                    item-key="name"
                    class="elevation-1"
                    hide-default-header
                    hide-default-footer
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-dialog
      v-model = "loading"
      fullscreen
      full-width
    >
      <v-container
        fluid
        class = "full-height"
        style = "background-color: rgba(255, 255, 255, 0.5);"
      >
        <v-layout
          align="center"
          justify="center"
          style="height:100vh"
          no-gutters
          dense
        >
          <v-flex
            class = "elementocentrado"
          >
            <v-progress-circular
              indeterminate
              color = "primary"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-dialog
      v-model="dialogmensaje"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          {{ mensajetitulo }}
        </v-card-title>
        <v-card-text>
          {{ mensajecuerpo }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="green darken-1"
            text
            @click="dialogmensaje = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from '@/axios'
  export default {
    name: 'Matriculado',
    data: () => ({
      loading: false,
      matriculado: {
        documento: '',
        apellido: '',
        nombre: '',
        apenom: '',
        foto: '',
        codigoCF: '',
        domicilios: [],
        telefonos: [],
        correos: [],
        matriculas: []
      },
      coldomicilios: [
        {
          text: 'Tipo',
          align: 'start',
          sortable: false,
          value: 'tipo',
        },
        {
          text: 'Domicilio',
          align: 'start',
          sortable: false,
          value: 'domicilio',
        },
        {
          text: 'CP',
          align: 'start',
          sortable: false,
          value: 'codpostal',
        },
        {
          text: 'Localidad',
          align: 'start',
          sortable: false,
          value: 'localidad',
        }
      ],
      coltelefonos: [
        {
          text: 'Tipo',
          align: 'start',
          sortable: false,
          value: 'tipo',
        },
        {
          text: 'Número',
          align: 'start',
          sortable: false,
          value: 'telefono',
        }
      ],
      colcorreos: [
        {
          text: 'Correo',
          align: 'start',
          sortable: false,
          value: 'correo',
        },
      ],
      colmatriculas: [
        {
          text: 'Tipo',
          align: 'start',
          sortable: false,
          value: 'tipotitulo',
        },
        {
          text: 'Tomo y Folio',
          align: 'start',
          sortable: false,
          value: 'tomofolio',
        },
        {
          text: 'Estado Actual',
          align: 'start',
          sortable: false,
          value: 'estado',
        },
        {
          text: 'Fecha Matrícula',
          align: 'start',
          sortable: false,
          value: 'fechamatricula',
        }
      ],
      dialogmensaje: false,
      mensajetitulo: '',
      mensajecuerpo: '',
      paneldefecto: [0, 1]
    }),
    mounted() {
      this.loading = true
      var id= this.$route.params.id
      axios
        .get('matriculado/' + id)
        .then((result) => {
        this.matriculado = result.data
        this.loading = false
        })
        .catch((error) => {
        this.loading = false
        this.MostrarMensaje('Error', error)
        })
    },
    methods: {
      MostrarMensaje (titulo, cuerpo) {
        this.mensajetitulo = titulo
        this.mensajecuerpo = cuerpo
        this.dialogmensaje = true
      }
    }
  }
</script>
<style>
  .textocentrado {
    text-align: center !important;
  }
  .elementocentrado {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .DatosMatriculado {
      font-family: Verdana;
      font-size: 16pt !important;
  }

  .alineaizquierda {
    text-align: left !important;
  }
  .alineacentro {
    text-align: center !important;
  }
  .alineaderecha {
    text-align: right !important;
  }
  .textoblanco {
    color: rgba(255, 255, 255, 0.7);
  }
</style>
